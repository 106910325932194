const btcColor = '#f7931a'

export interface CryptoProp {
  label: string
  value: string
  ticker: string
  price: number
  color: string
}

export const btcInfo: CryptoProp = {
  label: 'BTC(BitCoin)',
  value: 'btc',
  ticker: 'KRW-BTC',
  price: 0,
  color: btcColor,
}

export const upbitAsset = ['BTC/KRW']
export const binaceAsset = ['btcusdt']
